import { Button, Checkbox, Col, Form, Image, Input, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import accountIcon from 'assets/image/account.svg';
import langIcon from 'assets/image/language.svg';
import passwordIcon from 'assets/image/password.svg';
import { ModalLayout } from "components/layout.component";
import { COOKIE } from "constants/cookie";
import { RESPONSE_CODE } from "constants/response";
import useAccount from "hooks/account.hook";
import useLanguage, { LANG } from "hooks/language.hook";
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { $api } from "services";

// 登入畫面

const PageMain: React.FC = () => {
  const { i18n } = useTranslation();
  const { login } = useAccount();
  const { list: languageList, getLang: lang, setLang } = useLanguage();

  // 聖經once
  const { errorCode } = useParams();
  const initialized = useRef(false)
  useEffect(() => {
    if (errorCode && !initialized.current) {
      initialized.current = true;
      message.error(RESPONSE_CODE[errorCode as keyof typeof RESPONSE_CODE]);
    }
  }, []);

  const [form] = useForm();
  const [rememberMe, setRememberMe] = useState(Cookies.get(COOKIE.REMEBER_ME) === 'true');

  useEffect(() => {
    // NOTE: 應該要能用setFieldsValue就改變Checkbox
    const rememberMe = Cookies.get(COOKIE.REMEBER_ME) === 'true'
    if (rememberMe) {
      form.setFieldsValue({
        account: Cookies.get(COOKIE.ACCOUNT),
      })
      setRememberMe(rememberMe)
    }

    form.setFieldsValue({
      Language: lang
    })

  }, [])

  const onFinish = (formData: any) => {
    $api('POST', {
      url: 'admin/login',
      send: {
        account: formData.account,
        password: formData.password
      },
      success: (response: any) => {
        login(response.token);

        if (rememberMe) {
          Cookies.set(COOKIE.ACCOUNT, formData.account);
        }
        Cookies.set(COOKIE.REMEBER_ME, rememberMe ? 'true' : 'false');

        message.success(i18n.t('loginSuccess'));
        form.resetFields();

        window.location.href = '/';
      },
      resCode: RESPONSE_CODE
    })
  };

  return (
    <ModalLayout>
      <Form size="middle" onFinish={onFinish} form={form}>
        <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
          <div>{i18n.t('generalControlDashboard')}</div>
        </Form.Item>
        <Form.Item name="Language">
          <Select
            onChange={val => setLang(val)}
            options={
              languageList.map((item: any) => ({
                value: item,
                label: (
                  <Row align="middle" gutter={[5, 0]}>
                    <Col><Image src={langIcon} width={20} height={20} preview={false} /></Col>
                    <Col>{LANG[item as keyof typeof LANG]}</Col>
                  </Row>
                )
              }))
            }
          />
        </Form.Item>
        <Form.Item
          name="account"
          className="mt-1"
          rules={[{ required: true, message: `${i18n.t("enterAccount")}` }]}
        >
          <Input
            prefix={
              <Image rootClassName="center" src={accountIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t('inputData')}${i18n.t('account')}`}
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="mt-1"
          rules={[{ required: true, message: `${i18n.t("enterPassword")}` }]}
        >
          <Input.Password
            prefix={
              <Image rootClassName="center" src={passwordIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t("inputData")}${i18n.t("password")}`}
          />
        </Form.Item>
        <Form.Item className="center mt-1" name="remember">
          <Checkbox checked={rememberMe} onChange={(e: any) => setRememberMe(e.target.checked)}>
            {i18n.t("rememberAccount")}
          </Checkbox>
        </Form.Item>
        <Form.Item className="center mt-1" name="login">
          <Button
            htmlType="submit"
            style={{ background: '#DABF8D' }}
            className="w-10 login-btn"
            size="large"
          >
            {i18n.t("login")}
          </Button>
        </Form.Item>
      </Form>
    </ModalLayout>
  );
};

export default PageMain;
