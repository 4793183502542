import { COOKIE } from "constants/cookie";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { $get } from "services";

// 帳號與權限資料

export interface AccountInfo {
  id?: number;
  account?: string;
  name?: string | null;
}

const useAccount = () => {

  const [userToken, setUserToken] = useState<string | null | undefined>(Cookies.get(COOKIE.TOKEN));

  useEffect(() => {
    if (!userToken && !window.location.hash.includes('#/login')) {
      window.location.replace('/#/login/UNAUTHORIZATION');
    }
  }, [userToken]);

  // 帳號資訊
  const { data: _account } = $get({ url: 'admin/permission/self/info' });
  const [info, setInfo] = useState<AccountInfo>({});

  useEffect(() => {
    if (_account) {
      setInfo(_account?.Data);
    }
  }, [_account])

  // 權限
  const { data: _permission, isValidating: permissionValidating } = $get({ url: 'admin/permission/self/operations' });
  const [permissionIds, setPermissionIds] = useState<string[]>([]);

  useEffect(() => {
    if (_permission && Array.isArray(_permission.Data)) {
      const ids = _permission.Data.map((item: any) => item.id);
      setPermissionIds(ids.concat(['-1']));
    }
    // if (_account && _permission) {
    //   const ary = _permission.Data.map((item: any) => item.OperationId);
    //   if (account.Id === 0) setPermissionCode(ary.concat(['99999']));
    //   else setPermissionCode(ary);
    // }
  }, [_permission])

  // 是否取得資料
  const [init, setInit] = useState<boolean>(false);
  
  useEffect(() => {
    if (Object.keys(info).length > 0 && permissionIds.includes('-1')) {
      setInit(true);
    }
  }, [info, permissionIds])

  // 權限檢查
  const permissions = (id: string) => {
    return permissionIds.includes(id);
  };

  // 執行登入
  const login = async (token: string) => {
    Cookies.set(COOKIE.TOKEN, token);
    setUserToken(token);
  };

  // 執行登出
  const logout = () => {
    Cookies.remove(COOKIE.TOKEN);
    Cookies.remove(COOKIE.FIRST_LOGIN);
    Cookies.remove(COOKIE.LOGO_02);
    setUserToken(null);
  };
  
  return {
    init,
    info,
    permissions,
    login,
    logout,
    permissionValidating
  }
};

export default useAccount;
