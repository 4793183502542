import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Spin, Table, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import i18n from 'i18n';
import { $get } from "services";
import { useEffect, useState } from "react";
import { convertedToPercentage01, numColor03, timeS2L, toFormatNumber } from "utils/common";
import { SMS_STATE } from 'constants/state';

// 短信記錄列表
export const ViewSMSList = ({ data, isHaveSiteId, siteId}: any) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case SMS_STATE.success:
        return 'color-pass'
      case SMS_STATE.fail:
        return 'color-reject'
      default: return 'color-padding'
    }
  }

  const handleDetail = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      <Row className="mb-1" gutter={[12, 12]}>
        <Col>
          <Button type="primary" disabled={!isHaveSiteId} onClick={() => handleDetail()}>
            {i18n.t('detail')}
          </Button>
        </Col>
      </Row>
      {isHaveSiteId &&
      <Row gutter={[12, 12]}>
        <Col>
          <span className="size-16 font-w-lg ">{`${i18n.t('platformCurrency')} : ${data?.Headers.get('Currency') || ''}`}</span>
        </Col>
      </Row>
      }
      <Table
        className="mt-1"
        size="middle"
        dataSource={data?.Data?.map((item: any, i: number) => ({ key: i, ...item }))}
        columns={[
          {
            dataIndex: 'Merchant',
            width: 150,
            title: i18n.t('SmsMerchantCode'),
          },
          {
            dataIndex: 'SendTime',
            width: 180,
            title: i18n.t('sendTime'),
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Role',
            width: 150,
            title: `${i18n.t('member')}／${i18n.t('agent')}`,
            render: (val) => val ? i18n.t('agent') : i18n.t('member')
          },
          {
            dataIndex: 'Account',
            width: 150,
            title: i18n.t('account'),
          },
          {
            dataIndex: 'PhoneNumber',
            width: 150,
            title: i18n.t('phoneNumber'),
            render: (val, { PhonePrefix }) => `${PhonePrefix}-${val}`
          },
          {
            dataIndex: 'Status',
            width: 150,
            title: i18n.t('status'),
            render: (val) => <div className={stateColor(val)}>{i18n.t(SMS_STATE[val])}</div>
          },
          {
            dataIndex: 'Content',
            title: i18n.t('content'),
            render: (val) => val || '-'
          },
        ]}
        pagination={false}
      />
      <PopupSmsModal isOpen={isOpenModal} close={() => setIsOpenModal(false)} siteId={siteId}/>
    </>
  )
}

// 詳情彈窗
export const PopupSmsModal = ({ isOpen, close, siteId}: any) => {
  const [form] = useForm();
  const { data: smsTemplateInfo, isValidating } = $get({
    url: `admin/sms-log/sites/${siteId}/template/info`,
    allow: !!siteId
  })

  useEffect(() => {
    if (smsTemplateInfo && isOpen) {
      form.setFieldsValue({
        Verification: smsTemplateInfo?.Data[0]?.Code,
        Forget: smsTemplateInfo?.Data[1]?.Code,
      })
    }
  }, [smsTemplateInfo, isOpen]);

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form}>
        <Spin spinning={isValidating}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="Verification"
                label={
                  <>
                    {i18n.t('vertificationCodeContent')}
                    <Tooltip placement="top" className="size-12" title={i18n.t('verificationCodeRandomlyGeneratedBySystem')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </>
                }
                rules={[{ required: true, type: 'string', max: 200 }]}>
                <Input.TextArea disabled autoSize={{ minRows: 3, maxRows: 5 }} maxLength={200} showCount />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Forget"
                label={
                  <>
                    {i18n.t('forgetPasswordContent')}
                    <Tooltip placement="top" className="size-12" title={i18n.t('newPasswordBeDisplayed')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </>
                }
                rules={[{ required: true, type: 'string', max: 200 }]}>
                <Input.TextArea disabled autoSize={{ minRows: 3, maxRows: 5 }} maxLength={200} showCount />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" className="mt-1" gutter={[12, 12]}>
                <Col>
                  <Button key="cancel" onClick={onClose}>{i18n.t('close')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 報表 交收報表
export const ViewSettlementList = ({ loading, data, columns, reset }: any) => {
  const [list, setList] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>('');

  useEffect(() => {
    if (data) {
      setTimeout(init);
    }
  }, [data, reset]);

  const init = () => {
    setActiveKey(0);
    setList([]);
  }

  // 展開按鈕設定
  const expandable = {
    rowExpandable: (record: any) => record.rowExpandable && record.Detail.length > 0,
    expandedRowRender: () => <></>,
    // 展開遊戲大類
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setActiveKey(record.ProviderCode);
    setList(record.Detail)
  }

  // 收合
  const backto = (record: any) => {
    init();
  }

  return (
    <>
      <Table
        loading={loading}
        size="middle"
        expandable={{ ...expandable, expandedRowKeys: [activeKey] }}
        dataSource={
          data?.Data?.map((item: any) => ({ key: item.ProviderCode, rowExpandable: true, ...item }))
            .filter((item: any) => item.key === activeKey || !activeKey)
        }
        columns={columns}
        pagination={false}
      />
      {!!activeKey &&
        <Table
          size="middle"
          expandable={{ ...expandable, expandedRowKeys: [activeKey] }}
          dataSource={
            list?.map((item: any) => ({ key: item.ProviderId, ...item }))
          }
          columns={[
            {
              dataIndex: 'CategoryCode',
              title: i18n.t('gameCategory'),
              width: 150,
              render: (val) => i18n.t(val)
            },
            {
              dataIndex: 'Rate',
              title: `${i18n.t('rate')}%`,
              align: 'right',
              width: 150,
              render: (val) => convertedToPercentage01(val)
            },
            {
              dataIndex: 'BetAmount',
              title: i18n.t('totalBetAmount'),
              align: 'right',
              width: 150,
              render: (val) => toFormatNumber(val)
            },
            {
              dataIndex: 'ValidBetAmount',
              title: i18n.t('totalValidBets'),
              align: 'right',
              width: 150,
              render: (val) => toFormatNumber(val)
            },
            {
              dataIndex: 'WinLossAmount',
              title: i18n.t('totalGamePnL'),
              align: 'right',
              width: 150,
              render: (val) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
            },
            {
              dataIndex: 'ReceivableAmount',
              title: i18n.t('totalSettlementAmount'),
              align: 'right',
              width: 150,
              render: (val) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
            },
            // 對齊用
            {
              dataIndex: '',
              title: '',
              width: 150,
              render: (val: any) => ''
            },
            {
              dataIndex: 'DonateAmount',
              title: i18n.t('totalDonateAmount'),
              align: 'right',
              width: 150,
              render: (val) => toFormatNumber(val)
            },
          ]}
          pagination={false}
        />}
    </>
  )
}